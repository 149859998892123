<template>
    <v-dialog v-model="createOps.dialog" max-width="500px">
        <v-card flat>
            <v-card-title>
                <v-row align="center">
                    <v-col cols="10">
                        <span>Create Customer</span>
                    </v-col>
                    <v-col cols="2">
                        <div class="float-right">
                            <CustomButton
                                btnIcon="mdi-close"
                                tooltipMsg="Close"
                                @click="hideDialog"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-divider />

            <v-card-text class="px-4">
                <v-card flat>
                    <v-card-text>
                        <ValidationObserver ref="observer" v-slot="{}">
                            <v-form>
                                <v-row dense>
                                    <v-col cols="12">
                                        <CustomLabel
                                            for="name"
                                            label="Name"
                                            required
                                        />
                                        <CustomTextField
                                            id="name"
                                            inputPlaceholder="Name"
                                            :inputValue="createPayload.name"
                                            validationName="name"
                                            validationRules="required"
                                            @input="
                                                updateForm(
                                                    $event,
                                                    'createPayload.name'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col cols="12">
                                        <CustomLabel
                                            for="abbreviation"
                                            label="Abbreviation"
                                            required
                                        />
                                        <CustomTextField
                                            id="abbreviation"
                                            inputPlaceholder="Abbreviation"
                                            :inputValue="
                                                createPayload.abbreviation
                                            "
                                            validationName="abbreviation"
                                            validationRules="required"
                                            @input="
                                                updateForm(
                                                    $event,
                                                    'createPayload.abbreviation'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col cols="12">
                                        <CustomLabel
                                            for="email"
                                            label="Email"
                                            required
                                        />
                                        <CustomTextField
                                            id="email"
                                            inputPlaceholder="Email Address"
                                            :inputValue="createPayload.email"
                                            validationName="email"
                                            validationRules="required"
                                            @input="
                                                updateForm(
                                                    $event,
                                                    'createPayload.email'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col cols="12" sm="6">
                                        <CustomLabel
                                            for="first_name"
                                            label="First Name"
                                        />
                                        <CustomTextField
                                            id="first_name"
                                            inputPlaceholder="First Name"
                                            :inputValue="
                                                createPayload.first_name
                                            "
                                            @input="
                                                updateForm(
                                                    $event,
                                                    'createPayload.first_name'
                                                )
                                            "
                                        />
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <CustomLabel
                                            for="last_name"
                                            label="Last Name"
                                        />
                                        <CustomTextField
                                            id="last_name"
                                            inputPlaceholder="Last Name"
                                            :inputValue="
                                                createPayload.last_name
                                            "
                                            @input="
                                                updateForm(
                                                    $event,
                                                    'createPayload.last_name'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col cols="12">
                                        <CustomLabel
                                            for="full_name"
                                            label="Full Name"
                                        />
                                        <CustomTextField
                                            id="full_name"
                                            inputPlaceholder="Full Name"
                                            :inputValue="
                                                createPayload.full_name
                                            "
                                            @input="
                                                updateForm(
                                                    $event,
                                                    'createPayload.full_name'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col cols="12">
                                        <CustomLabel
                                            for="rate"
                                            label="Rate"
                                            required
                                        />
                                        <CustomTextField
                                            id="rate"
                                            inputPlaceholder="Rate"
                                            :inputValue="createPayload.rate"
                                            validationName="rate"
                                            validationRules="required"
                                            @input="
                                                updateForm(
                                                    $event,
                                                    'createPayload.rate'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row class="mt-4">
                                    <v-col>
                                        <CustomButton
                                            btnLabel="Cancel"
                                            tooltipMsg="Cancel"
                                            @click="hideDialog"
                                        />
                                        <CustomButton
                                            btnColour="primary"
                                            :btnIsLoading="createOps.isLoading"
                                            btnLabel="Save"
                                            tooltipMsg="Save"
                                            @click="createItems(createPayload)"
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </ValidationObserver>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    computed: {
        ...mapState("customersAdminMgmt", ["createOps", "createPayload"]),
    },
    data: () => ({}),
    methods: {
        async createItems(payload) {
            let valid = await this.$refs.observer.validate();

            if (!valid) {
                return;
            }

            let response = await this.$store.dispatch(
                "customersAdminMgmt/createItems",
                payload
            );

            if (response) {
                this.$refs.observer.reset();
            }
        },
        hideDialog() {
            this.$store.commit("customersAdminMgmt/HIDE_CREATE_OPS_DIALOG");
            this.$store.commit(
                "customersAdminMgmt/FLUSH_FIELD",
                "createPayload"
            );
            this.$refs.observer.reset();
        },
        updateForm(event, form) {
            if (event instanceof InputEvent) {
                this.$store.commit("customersAdminMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event.target.value,
                    },
                });
                return;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this.$store.commit("customersAdminMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event,
                    },
                });
                return;
            } else {
                return;
            }
        },
    },
    name: "DialogCustomerCreate",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
